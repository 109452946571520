<template>
    <v-row class="tw-bg-white">
        <v-col cols="6">
            <div class="tw-mx-4 tw-mt-4 tw-flex tw-items-center tw-justify-between tw-px-4 tw-pt-4">
                <p class="tw-text-2xl tw-font-bold">{{ $t('intelligence.metabase.title') }}</p>
                <v-spacer></v-spacer>
                <!-- <v-btn
            color="primary"
            :to="{ name: 'AM-CreateAsset' }"
            >{{ $t('shared.createNew') }}</v-btn
        > -->
            </div>
        </v-col>

        <v-col cols="6">
            <div class="tw-mr-8 tw-flex tw-flex-row tw-items-center tw-justify-end">
                <p class="tw-m-8 tw-mx-4 tw-text-right">{{ user.username }}</p>
                <v-avatar size="45">
                    <io-img
                        format="thumbnail"
                        class="no-padding tw-rounded-full"
                        :media="user?.picture"
                        content-type="Media"></io-img>
                </v-avatar>
            </div>
        </v-col>
    </v-row>
    <div class="tw-flex tw-flex-col tw-h-full">
  
        <!-- add an iframe that receives the url -->
        <iframe
            :src="iframeUrl"
            frameborder="0"
            class="tw-w-full tw-h-full tw-grow"
            allowtransparency="true"
            allowfullscreen></iframe>

        
        <v-overlay
            :model-value="isLoading"
            class="tw-items-center tw-justify-center"
            persistent>
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import { onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { getDashboard } from '@/views/Intelligence/Metabase/composables/metabase';
    
    const useAuth = useAuthStore();
    const user = useAuth.user;

    const isLoading = ref(false);
 
    const $route = useRoute();

    const iframeUrl = ref('');

    async function fetchDashboard(id: number) {
        isLoading.value = true;
        try {
            const response = await getDashboard(id);

            // set time to redo call
            setTimeout(() => {fetchDashboard(id); }, 
                response.data?.expiration || 60000);

            // in the response set the title to false to hide it
            let url = response.data?.iframeUrl;
            url = url.replace('titled=true', 'titled=false');
            url = url.replace('bordered=true', 'bordered=false');

            iframeUrl.value = url;
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            isLoading.value = false;
        }
    }

    watch($route, () => {
        fetchDashboard(Number($route.params.dashboard));
    });

    //fetch assets on mounted
    onMounted(() => {
        fetchDashboard(Number($route.params.dashboard));
    });
</script>

<style scoped></style>
<style>
    .v-date-picker-header {
        display: none;
    }

</style>
