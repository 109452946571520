import { APIResponseMany, APIResponseSingle, ContentType } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/metabase/dashboards';
export const API_REF = 'plugin::metabase.metabase';

export async function getDashboard(id: number): AxiosPromise<any> {
    return axios.get(`${API_URL}/${id}`);
}


export default {
    getDashboard,
};
